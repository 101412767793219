// :root {
//   --max-width: 1100px;
//   --border-radius: 12px;
//   --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
//     "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
//     "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

//   --foreground-rgb: 0, 0, 0;
//   --background-start-rgb: 214, 219, 220;
//   --background-end-rgb: 255, 255, 255;

//   --primary-glow: conic-gradient(
//     from 180deg at 50% 50%,
//     #16abff33 0deg,
//     #0885ff33 55deg,
//     #54d6ff33 120deg,
//     #0071ff33 160deg,
//     transparent 360deg
//   );
//   --secondary-glow: radial-gradient(
//     rgba(255, 255, 255, 1),
//     rgba(255, 255, 255, 0)
//   );

//   --tile-start-rgb: 239, 245, 249;
//   --tile-end-rgb: 228, 232, 233;
//   --tile-border: conic-gradient(
//     #00000080,
//     #00000040,
//     #00000030,
//     #00000020,
//     #00000010,
//     #00000010,
//     #00000080
//   );

//   --callout-rgb: 238, 240, 241;
//   --callout-border-rgb: 172, 175, 176;
//   --card-rgb: 180, 185, 188;
//   --card-border-rgb: 131, 134, 135;
// }

// @media (prefers-color-scheme: dark) {
//   :root {
//     --foreground-rgb: 255, 255, 255;
//     --background-start-rgb: 0, 0, 0;
//     --background-end-rgb: 0, 0, 0;

//     --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
//     --secondary-glow: linear-gradient(
//       to bottom right,
//       rgba(1, 65, 255, 0),
//       rgba(1, 65, 255, 0),
//       rgba(1, 65, 255, 0.3)
//     );

//     --tile-start-rgb: 2, 13, 46;
//     --tile-end-rgb: 2, 5, 19;
//     --tile-border: conic-gradient(
//       #ffffff80,
//       #ffffff40,
//       #ffffff30,
//       #ffffff20,
//       #ffffff10,
//       #ffffff10,
//       #ffffff80
//     );

//     --callout-rgb: 20, 20, 20;
//     --callout-border-rgb: 108, 108, 108;
//     --card-rgb: 100, 100, 100;
//     --card-border-rgb: 200, 200, 200;
//   }
// }

// * {
//   box-sizing: border-box;
//   padding: 0;
//   margin: 0;
// }

// html,
// body {
//   max-width: 100vw;
//   overflow-x: hidden;
// }

// body {
//   color: rgb(var(--foreground-rgb));
//   background: linear-gradient(
//       to bottom,
//       transparent,
//       rgb(var(--background-end-rgb))
//     )
//     rgb(var(--background-start-rgb));
// }

// a {
//   color: inherit;
//   text-decoration: none;
// }

// @media (prefers-color-scheme: dark) {
//   html {
//     color-scheme: dark;
//   }
// }

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
}

@font-face {
  font-family: "Inter", sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: "#FFFFFF";
  font-family: "Poppins", sans-serif;
  width: 100vw;
  /* overflow-x: hidden; */
  /* always update according tpp bar height  */
  min-height: calc(100vh - 84px);
}

/* end */

.description-offcanvas .MuiPaper-root {
  width: 40%;
  height: 70%;
  top: 15%;
}

@media (max-width: 767px) {
  .description-offcanvas .MuiPaper-root {
    width: 80%;
    height: 40%;
    top: 30%;
  }
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #e498aa 0%,
    #fff 29%,
    #e498aa 67%,
    #fff 100%
  );

  /* background-size: auto auto; */
  /* background-clip: border-box; */
  background-size: 200% auto;
  color: #fff;
  box-shadow: "0px 4px 4px rgba(232, 52, 94, 0.7)";
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  text-transform: none;
  /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.swiper-pagination-horizontal {
  bottom: 0px !important;
}

.swiper-pagination{
  position: static !important;
}
